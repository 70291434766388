import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setSubmissionStatus('success');
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            } else {
                setSubmissionStatus('error');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmissionStatus('error');
        }
    };

    return (
        <div className="container mx-auto p-4 sm:p-6 lg:p-8">
            <h1 className="text-4xl font-bold mb-6 text-center">Contact Us</h1>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                <div className="mb-4">
                    <label className="block text-left text-lg mb-2">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-3 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-left text-lg mb-2">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-3 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-left text-lg mb-2">Subject</label>
                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full p-3 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-left text-lg mb-2">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-3 border rounded"
                        rows="5"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 w-full sm:w-auto"
                >
                    Submit
                </button>
            </form>

            {submissionStatus === 'success' && (
                <div className="mt-6 text-center text-green-600">
                    <p>Your message has been sent successfully!</p>
                    <p>Redirecting you to the homepage...</p>
                </div>
            )}

            {submissionStatus === 'error' && (
                <div className="mt-6 text-center text-red-600">
                    <p>There was an error submitting your message. Please try again.</p>
                </div>
            )}
        </div>
    );
};

export default Contact;
