import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const navigate = useNavigate();

    const handleServicesClick = () => {
        if (!isServicesOpen) {
            setIsServicesOpen(true);
            setIsProductsOpen(false);
            navigate('/services');
        } else {
            setIsServicesOpen(false);
        }
    };

    const handleProductsClick = () => {
        if (!isProductsOpen) {
            setIsProductsOpen(true);
            setIsServicesOpen(false);
            navigate('/products');
        } else {
            setIsProductsOpen(false);
        }
    };

    const handleCloseMenus = () => {
        setIsServicesOpen(false);
        setIsProductsOpen(false);
    };

    return (
        <header className="bg-gradient-to-r from-blue-800 to-blue-600 text-white p-4" onClick={handleCloseMenus}>
            <nav className="container mx-auto flex justify-between items-center">
                <div className="text-lg font-bold flex flex-col items-start">
                    <Link to="/" className="hover:underline">
                        <span>cyber<span className="font-bold text-red-600 hover:animate-pulse">HIA</span></span>
                    </Link>
                    <span className="text-sm text-gray-300 mt-1">Keeping Hackers, Intruders, and Attackers at Bay</span>
                </div>
                <ul className="flex space-x-4">
                    <li><Link to="/" className="hover:text-red-400">Home</Link></li>
                    <li><Link to="/about" className="hover:text-red-400">About Us</Link></li>
                    <li className="relative" onClick={(e) => e.stopPropagation()}>
                        <button className="hover:text-red-400 focus:outline-none" onClick={handleServicesClick}>Services</button>
                        {isServicesOpen && (
                            <ul className="absolute bg-white text-black p-2 mt-2 rounded shadow-lg w-48" onMouseLeave={() => setIsServicesOpen(false)}>
                                <li><Link to="/cyber-threat-exposure" className="block px-4 py-2 hover:bg-gray-200">Cyber Threat Exposure Management</Link></li>
                                <li><Link to="/vulnerability-assessment" className="block px-4 py-2 hover:bg-gray-200">Vulnerability Assessment</Link></li>
                                <li><Link to="/penetration-testing" className="block px-4 py-2 hover:bg-gray-200">Penetration Testing</Link></li>
                            </ul>
                        )}
                    </li>
                    <li className="relative" onClick={(e) => e.stopPropagation()}>
                        <button className="hover:text-red-400 focus:outline-none" onClick={handleProductsClick}>Products</button>
                        {isProductsOpen && (
                            <ul className="absolute bg-white text-black p-2 mt-2 rounded shadow-lg w-48" onMouseLeave={() => setIsProductsOpen(false)}>
                                <li><Link to="/cyberaiops" className="block px-4 py-2 hover:bg-gray-200">CyberAIOPx</Link></li>
                                <li><Link to="/defendacademy" className="block px-4 py-2 hover:bg-gray-200">DefendAcademy</Link></li>
                            </ul>
                        )}
                    </li>
                    <li><Link to="/pricing" className="hover:text-red-400">Pricing</Link></li>
                    <li><Link to="/contact" className="hover:text-red-400">Contact Us</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
