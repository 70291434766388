import React from 'react';
import { motion } from 'framer-motion';

const PenetrationTesting = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/penetration-testing.jpg" alt="Penetration Testing" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">Penetration Testing</h1>
                <p className="text-lg mb-4">
                    Penetration testing simulates real-world cyber attacks on your systems to identify vulnerabilities before they can be exploited by malicious actors. Our Penetration Testing service is designed to uncover weaknesses in your defenses, providing you with the insights needed to strengthen your security posture.
                </p>
                <p className="text-lg mb-4">
                    With our service, you can:
                </p>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Simulate attacks to identify vulnerabilities that could be exploited by cybercriminals.</li>
                    <li>Receive a detailed report of findings, including critical vulnerabilities and recommended remediation actions.</li>
                    <li>Test the effectiveness of your existing security measures and improve them based on the results.</li>
                    <li>Enhance your organization's resilience against sophisticated cyber threats.</li>
                </ul>
                <p className="text-lg mb-4">
                    Penetration testing is an essential component of any robust cybersecurity strategy. Our service provides you with a clear understanding of your security gaps and the steps needed to close them, ensuring your business is well-protected against cyber attacks.
                </p>
                <a href="https://cyberaiopsx.cyberHIA.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    Register on the CyberAIOPx Platform
                </a>
            </div>
        </motion.div>
    );
};

export default PenetrationTesting;
