import React from 'react';
import { motion } from 'framer-motion';

const Services = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col space-y-4 p-4"
        >
            <div className="flex flex-col lg:flex-row items-start lg:space-x-3">
                <div className="lg:w-1/5">
                    <img src="/images/CyberThreat.jpg" alt="Cyber Threat Exposure Management" className="w-full h-auto rounded-lg shadow-lg" />
                </div>
                <div className="lg:w-4/5 mt-4 lg:mt-0">
                    <h1 className="text-2xl font-bold mb-3">Cyber Threat Exposure Management (CTEM)</h1>
                    <p className="text-sm mb-3">
                        In today's rapidly evolving digital landscape, cyber threats are becoming more sophisticated and harder to detect. Cyber Threat Exposure Management (CTEM) is a critical service that helps organizations understand their exposure to these threats in real-time. By identifying potential risks and vulnerabilities across your digital infrastructure, CTEM allows you to take proactive measures to safeguard your assets before threats materialize.
                    </p>
                    <p className="text-sm mb-3">
                        Whether you're dealing with advanced persistent threats, malware, or phishing attacks, our CTEM service provides continuous monitoring and assessment to keep your organization ahead of cyber adversaries. Don't wait until a breach happens—understand your threat exposure today.
                    </p>
                    <a href="/cyber-threat-exposure" className="text-blue-600 hover:underline">
                        Learn more about Cyber Threat Exposure Management
                    </a>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:space-x-3">
                <div className="lg:w-1/5">
                    <img src="/images/vulnerability-assessment.jpg" alt="Vulnerability Assessment" className="w-full h-auto rounded-lg shadow-lg" />
                </div>
                <div className="lg:w-4/5 mt-4 lg:mt-0">
                    <h1 className="text-2xl font-bold mb-3">Vulnerability Assessment (VA)</h1>
                    <p className="text-sm mb-3">
                        Every organization has digital assets that are crucial to its operations, but these assets often have vulnerabilities that can be exploited by cybercriminals. A Vulnerability Assessment (VA) is essential for identifying these weaknesses before they can be targeted. This service provides a comprehensive review of your systems, identifying vulnerabilities that could lead to data breaches, financial loss, or reputational damage.
                    </p>
                    <p className="text-sm mb-3">
                        Our VA service not only identifies potential vulnerabilities but also provides actionable recommendations to fortify your defenses. Regular assessments ensure that your systems remain secure and up-to-date, giving you peace of mind in a world where cyber threats are constantly evolving.
                    </p>
                    <a href="/vulnerability-assessment" className="text-blue-600 hover:underline">
                        Learn more about Vulnerability Assessment
                    </a>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:space-x-3">
                <div className="lg:w-1/5">
                    <img src="/images/penetration-testing.jpg" alt="Penetration Testing" className="w-full h-auto rounded-lg shadow-lg" />
                </div>
                <div className="lg:w-4/5 mt-4 lg:mt-0">
                    <h1 className="text-2xl font-bold mb-3">Penetration Testing (PT)</h1>
                    <p className="text-sm mb-3">
                        While vulnerability assessments help you find weaknesses, Penetration Testing (PT) goes a step further by simulating real-world attacks on your systems. This process helps you understand how an attacker could exploit vulnerabilities, and it provides a clear picture of how well your defenses hold up under pressure.
                    </p>
                    <p className="text-sm mb-3">
                        Penetration Testing is crucial for organizations that want to stay ahead of sophisticated cyber threats. By uncovering and addressing security gaps before they can be exploited, you can protect your most valuable assets and avoid costly breaches. Our PT service provides a thorough evaluation of your security posture, helping you build a more resilient infrastructure.
                    </p>
                    <a href="/penetration-testing" className="text-blue-600 hover:underline">
                        Learn more about Penetration Testing
                    </a>
                </div>
            </div>
        </motion.div>
    );
};

export default Services;
