import React from 'react';

// Get the current year dynamically
const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <footer className="bg-white text-gray-800 p-6 sm:p-8 lg:p-12">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-left">
                    <div>
                        <h3 className="text-lg font-bold mb-4 text-gray-900">Company</h3>
                        <ul className="space-y-2">
                            <li><a href="/about" className="text-gray-600 hover:text-gray-900 transition duration-300">About Us</a></li>
                            <li><a href="/products" className="text-gray-600 hover:text-gray-900 transition duration-300">Products</a></li>
                            <li><a href="/pricing" className="text-gray-600 hover:text-gray-900 transition duration-300">Pricing</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold mb-4 text-gray-900">Support</h3>
                        <ul className="space-y-2">
                            <li><a href="/contact" className="text-gray-600 hover:text-gray-900 transition duration-300">Contact Us</a></li>
                            <li><a href="/support" className="text-gray-600 hover:text-gray-900 transition duration-300">Support Portal</a></li>
                            <li><a href="/faq" className="text-gray-600 hover:text-gray-900 transition duration-300">FAQ</a></li>
                            <li><a href="/blog" className="text-gray-600 hover:text-gray-900 transition duration-300">Blog</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold mb-4 text-gray-900">Connect</h3>
                        <ul className="flex space-x-4 justify-start md:justify-center">
                            <li>
                                <a href="https://www.facebook.com/CyberHIA" className="hover:text-gray-900 transition duration-300">
                                    <img src="/images/facebook-logo.png" alt="Facebook" className="h-8 w-8"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.x.com/CyberHIA" className="hover:text-gray-900 transition duration-300">
                                    <img src="/images/x-logo.png" alt="X" className="h-8 w-8"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/cyberhia/" className="hover:text-gray-900 transition duration-300">
                                    <img src="/images/linkedin-logo.png" alt="LinkedIn" className="h-8 w-8"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <p className="text-gray-600">&copy; {currentYear} CyberHIA. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
