import React from 'react';
import { motion } from 'framer-motion';

const CyberAIOPx = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/CyberAIOPSx.jpg" alt="CyberAIOPx Platform" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">CyberAIOPx</h1>
                <p className="text-lg mb-4">
                    CyberAIOPx is our flagship platform designed to provide a comprehensive view of your organization's external cyber threat landscape. By integrating vulnerability management, threat intelligence, and benchmarking into a single platform, CyberAIOPx helps you stay ahead of potential threats.
                </p>
                <p className="text-lg mb-4">
                    With CyberAIOPx, you can:
                </p>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Identify vulnerabilities in your digital infrastructure and take proactive measures to mitigate risks.</li>
                    <li>Monitor emerging threats in real-time, ensuring you are always aware of the latest risks targeting your business.</li>
                    <li>Benchmark your security posture against industry standards, identifying areas for improvement.</li>
                    <li>Optimize your cybersecurity spending by focusing on the most critical areas of risk.</li>
                </ul>
                <p className="text-lg mb-4">
                    CyberAIOPx is essential for organizations looking to enhance their cybersecurity posture while reducing costs. Our platform is designed to be user-friendly, scalable, and customizable to meet the unique needs of your business.
                </p>
                <a href="https://cyberaiopsx.cyberHIA.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    Register on the CyberAIOPx Platform
                </a>
            </div>
        </motion.div>
    );
};

export default CyberAIOPx;
