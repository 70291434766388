import React from 'react';
import { motion } from 'framer-motion';

const CyberThreatExposure = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/CTEM.png" alt="Cyber Threat Exposure Management" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">Cyber Threat Exposure Management</h1>
                <p className="text-lg mb-4">
                    Understanding and managing your organization’s exposure to cyber threats is crucial in today’s digital world. Our Cyber Threat Exposure Management service provides you with the tools and insights needed to identify, assess, and mitigate potential threats before they can impact your business.
                </p>
                <p className="text-lg mb-4">
                    With our service, you can:
                </p>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Continuously monitor your external threat landscape, staying informed about emerging threats.</li>
                    <li>Analyze the impact of identified threats on your organization, allowing you to prioritize response efforts.</li>
                    <li>Implement proactive measures to reduce your risk exposure and strengthen your overall security posture.</li>
                    <li>Gain actionable intelligence that helps you stay ahead of cyber adversaries.</li>
                </ul>
                <p className="text-lg mb-4">
                    Cyber Threat Exposure Management is vital for organizations looking to protect their digital assets from increasingly sophisticated cyber threats. Our service is tailored to meet the specific needs of your business, ensuring you remain resilient against potential attacks.
                </p>
                <a href="https://cyberaiopsx.cyberHIA.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    Register on the CyberAIOPx Platform
                </a>
            </div>
        </motion.div>
    );
};

export default CyberThreatExposure;
