import React from 'react';

const Pricing = () => {
    return (
        <div className="container mx-auto p-6 text-center">
            <h1 className="text-5xl font-bold mb-8">Our Pricing</h1>
            
            {/* Free Tier */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                <div className="p-6 border rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold mb-4">Free Tier</h2>
                    <p className="text-lg mb-4">Free</p>
                    <ul className="text-left">
                        <li>Access to CyberAIOPx with limited features</li>
                        <li>1 domain</li>
                        <li>Community support</li>
                    </ul>
                </div>

                {/* Basic Tier */}
                <div className="p-6 border rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold mb-4">Basic Tier</h2>
                    <p className="text-lg mb-4">$49/month</p>
                    <ul className="text-left">
                        <li>Access to CyberAIOPx with basic features</li>
                        <li>5 domains</li>
                        <li>Email support</li>
                    </ul>
                </div>

                {/* Professional Tier */}
                <div className="p-6 border rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold mb-4">Professional Tier</h2>
                    <p className="text-lg mb-4">$149/month</p>
                    <ul className="text-left">
                        <li>Access to CyberAIOPx with full features</li>
                        <li>20 domains</li>
                        <li>Vulnerability Assessment service</li>
                        <li>Priority email and chat support</li>
                    </ul>
                </div>
            </div>

            {/* Growth Bundle */}
            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-4">Growth Bundle</h2>
                <p className="text-lg mb-4">$299/month</p>
                <ul className="list-disc list-inside text-left mx-auto max-w-lg">
                    <li>Access to CyberAIOPx with all features</li>
                    <li>Up to 100 domains</li>
                    <li>Includes DefendAcademy and Vulnerability Assessment</li>
                    <li>24/7 support, dedicated account manager</li>
                </ul>
            </div>

            {/* Custom Enterprise Solutions */}
            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-4">Custom Enterprise Solutions</h2>
                <p>Tailored solutions for large enterprises with custom features, unlimited access, and dedicated services.</p>
                <p>Contact us for a custom quote.</p>
                <a href="/contact" className="text-blue-600 hover:underline">Get in Touch &rarr;</a>
            </div>
        </div>
    );
};

export default Pricing;
