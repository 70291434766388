import React from 'react';
import { motion } from 'framer-motion';

const VulnerabilityAssessment = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/vulnerability-assessment.jpg" alt="Vulnerability Assessment" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">Vulnerability Assessment</h1>
                <p className="text-lg mb-4">
                    A thorough vulnerability assessment is essential for identifying and addressing weaknesses in your digital infrastructure before they can be exploited by cybercriminals. Our Vulnerability Assessment service provides you with a comprehensive analysis of your systems, highlighting potential vulnerabilities and offering actionable recommendations for remediation.
                </p>
                <p className="text-lg mb-4">
                    With our service, you can:
                </p>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Identify critical vulnerabilities in your systems that could be exploited by attackers.</li>
                    <li>Receive detailed reports with prioritized recommendations to enhance your security posture.</li>
                    <li>Ensure your digital assets are secure and compliant with industry standards and regulations.</li>
                    <li>Reduce the risk of cyber attacks by proactively addressing vulnerabilities before they can be exploited.</li>
                </ul>
                <p className="text-lg mb-4">
                    Regular vulnerability assessments are crucial for maintaining the security and integrity of your digital infrastructure. Our expert team provides the insights and guidance you need to stay ahead of potential threats.
                </p>
                <a href="https://cyberaiops.cyberHIA" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    Register on the CyberAIOPx Platform
                </a>
            </div>
        </motion.div>
    );
};

export default VulnerabilityAssessment;
