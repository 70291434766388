import React from 'react';
import { motion } from 'framer-motion';

const Home = () => {
    return (
        <div className="container mx-auto p-4 text-center">
            {/* Hero Section */}
            <motion.div 
                className="flex justify-center mb-8"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <img
                    src="/images/your-platform-image.jpg"
                    alt="Platform Overview"
                    className="w-full max-w-lg h-auto rounded shadow-lg"
                />
            </motion.div>
            <motion.h1
                className="text-4xl font-bold mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
            >
                Simplify Cybersecurity
            </motion.h1>
            <motion.p
                className="text-base md:text-lg mb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.7 }}
            >
                Your trusted platform for cybersecurity solutions, threat management, and expert consulting.
            </motion.p>
            <motion.button
                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.9 }}
            >
                Get Started for Free
            </motion.button>

            {/* Trust with Confidence Section */}
            <div className="flex flex-col lg:flex-row items-center lg:items-start mt-12 lg:mt-24 space-y-8 lg:space-y-0 lg:space-x-12">
                <div className="lg:w-1/2 text-left space-y-6">
                    <h2 className="text-3xl font-bold">Trust with Confidence</h2>
                    <div>
                        <h3 className="text-lg font-semibold">Customer-first Approach</h3>
                        <p>We prioritize our customers with personalized cybersecurity solutions tailored to your unique needs.</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold">No Gimmicks</h3>
                        <p>No unnecessary tools or complicated processes—just effective, easy-to-use solutions that work.</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold">The Certitude Ecosystem</h3>
                        <p>Our ecosystem of products, including CyberAIOPx and DefendAcademy, provides comprehensive security management.</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold">Continuous Improvement</h3>
                        <p>We keep your cybersecurity strategies up-to-date with the latest trends and technologies.</p>
                    </div>
                </div>
                <div className="lg:w-1/2 flex justify-center">
                    <motion.div
                        className="relative w-full max-w-md"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                    >
                        <img
                            src="/images/your-ecosystem-visual.jpg"
                            alt="Certitude Ecosystem"
                            className="w-full h-auto rounded shadow-lg"
                        />
                    </motion.div>
                </div>
            </div>

            {/* Unbeatable Pricing Section */}
            <div className="mt-24 text-left">
                <h2 className="text-3xl font-bold mb-4">Flexible Pricing for Every Need</h2>
                <p className="text-lg mb-4">We offer transparent pricing with tailored plans to suit businesses of all sizes, ensuring you get the best value for your cybersecurity needs.</p>
                <a href="/pricing" className="text-blue-600 hover:underline mb-12 inline-block">Explore our plans &rarr;</a>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
                    <div className="text-center">
                        <h3 className="text-4xl font-bold text-yellow-500 mb-2">Free</h3>
                        <p className="text-lg">1 domain with basic features</p>
                    </div>
                    <div className="text-center">
                        <h3 className="text-4xl font-bold text-yellow-500 mb-2">$49</h3>
                        <p className="text-lg">Basic Tier - 5 domains</p>
                    </div>
                    <div className="text-center">
                        <h3 className="text-4xl font-bold text-yellow-500 mb-2">$149</h3>
                        <p className="text-lg">Professional Tier - 20 domains</p>
                    </div>
                    <div className="text-center">
                        <h3 className="text-4xl font-bold text-yellow-500 mb-2">$299</h3>
                        <p className="text-lg">Growth Bundle - 100 domains</p>
                    </div>
                </div>
            </div>

            {/* Free and Open Market Education Section */}
            <div className="mt-24 flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
                <div className="lg:w-1/2 flex justify-center">
                    <img
                        src="/images/your-education-visual.jpg"
                        alt="Free and Open Market Education"
                        className="w-full max-w-sm h-auto rounded shadow-lg"
                    />
                </div>
                <div className="lg:w-1/2 text-left">
                    <h2 className="text-3xl font-bold mb-4">Free and Open Cybersecurity Education</h2>
                    <p className="text-lg mb-4">Our platform offers the most extensive cybersecurity education resources, covering everything from basic concepts to advanced practices.</p>
                    <a href="/defendacademy" className="text-blue-600 hover:underline">Explore our resources &rarr;</a>
                </div>
            </div>
        </div>
    );
};

export default Home;
