import React from 'react';
import { motion } from 'framer-motion';

const DefendAcademy = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/your-education-visual.jpg" alt="DefendAcademy Platform" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">DefendAcademy</h1>
                <p className="text-lg mb-4">
                    DefendAcademy is our comprehensive training platform, designed to empower your team with the knowledge and skills they need to defend against cyber threats. Whether you’re looking for introductory courses or in-depth, specialized training, DefendAcademy has you covered.
                </p>
                <p className="text-lg mb-4">
                    With DefendAcademy, you can:
                </p>
                <ul className="list-disc list-inside text-lg mb-4">
                    <li>Access a wide range of cybersecurity courses, from foundational to advanced levels.</li>
                    <li>Participate in hands-on training sessions led by industry experts.</li>
                    <li>Customize your learning experience to fit the specific needs of your organization.</li>
                    <li>Stay updated on the latest cybersecurity trends and practices to ensure your team remains prepared.</li>
                </ul>
                <p className="text-lg mb-4">
                    DefendAcademy is essential for organizations that want to stay ahead of cyber threats by continuously improving their team’s capabilities. Our platform is user-friendly, flexible, and designed to accommodate learners at all levels.
                </p>
                <a href="https://defendacademy.cyberHIA.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    Register on the DefendAcademy Platform
                </a>
            </div>
        </motion.div>
    );
};

export default DefendAcademy;
