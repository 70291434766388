import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col lg:flex-row items-center p-6 space-y-6 lg:space-y-0 lg:space-x-8"
        >
            <div className="lg:w-1/2">
                <img src="/images/team.jpg" alt="Our Team" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">About Us</h1>
                <p className="text-lg mb-4">
                    At cyberHIA, we are driven by the mission to create a safer digital world for businesses of all sizes. Founded by a team of cybersecurity experts, our goal is to simplify the complex landscape of cyber threats, making it more accessible and affordable for everyone.
                </p>
                <p className="text-lg mb-4">
                    We understand that navigating the ever-changing world of cybersecurity can be overwhelming. That's why we're here—to provide you with the tools, knowledge, and support you need to protect your digital assets with confidence and keep Hackers, Intruders, and Attackers (HIA) at bay.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Why We Are Here</h2>
                <p className="text-lg mb-4">
                    cyberHIA was born out of the realization that many businesses struggle with the high costs and complexities associated with cybersecurity. We saw an opportunity to make a difference by offering cost-effective, tailored solutions that don't compromise on quality or effectiveness.
                </p>
                <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
                <p className="text-lg mb-4">
                    We provide a range of cybersecurity services and products designed to meet the unique needs of your business. From comprehensive threat exposure management to vulnerability assessments and bespoke training programs, we cover every aspect of cybersecurity to ensure your organization is well-protected from HIA threats.
                </p>
                <h2 className="text-2xl font-semibold mb-4">How cyberHIA Can Help</h2>
                <p className="text-lg mb-4">
                    Our team of experts is dedicated to helping you navigate the complexities of cybersecurity. Whether you're looking to improve your threat detection capabilities, enhance your team's skills through targeted training, or simply want to reduce your cybersecurity costs, we have the solutions you need.
                </p>
                <p className="text-lg mb-4">
                    By partnering with cyberHIA, you gain access to top-tier cybersecurity services and products at a fraction of the traditional cost. Our approach is flexible, scalable, and designed to grow with your business, ensuring you stay protected as your needs evolve.
                </p>
            </div>
        </motion.div>
    );
};

export default About;
