import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import Pricing from './pages/Pricing'; // Import the new Pricing component
import Contact from './pages/Contact';
import CyberAIOPx from './pages/CyberAIOPx';
import DefendAcademy from './pages/DefendAcademy';
import CyberThreatExposure from './pages/CyberThreatExposure';
import VulnerabilityAssessment from './pages/VulnerabilityAssessment';
import PenetrationTesting from './pages/PenetrationTesting';

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/pricing" element={<Pricing />} /> {/* Add Pricing route here */}
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/cyberaiops" element={<CyberAIOPx />} />
                    <Route path="/defendacademy" element={<DefendAcademy />} />
                    <Route path="/cyber-threat-exposure" element={<CyberThreatExposure />} />
                    <Route path="/vulnerability-assessment" element={<VulnerabilityAssessment />} />
                    <Route path="/penetration-testing" element={<PenetrationTesting />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
